// libs
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Hooks
import useColorTheme from '../../../../hooks/use-color-theme';
// Styles
import useStyles from './CircularProgressWithLabel.styles';

type CircularProgressWithLabelPropsType = {
  value: number;
};

const CircularProgressWithLabel : React.FC<CircularProgressWithLabelPropsType> = ({ value }) => {
  const classes = useStyles();
  const defaultCircularProgressColor = useColorTheme('light');
  const activeCircularProgressColor = useColorTheme('main');

  return (
    <Box className={classes.container}>
      <CircularProgress
        variant='determinate'
        value={100}
        size={'70vw'}
        thickness={3}
        className={classes.defaultCircularProgress}
        sx={{ color: defaultCircularProgressColor }}
      />
      <CircularProgress
        variant='determinate'
        value={value}
        size={'70vw'}
        thickness={3}
        className={classes.activeCircularProgress}
        sx={{
          circle: {
            strokeLinecap: 'round'
          },
          color: activeCircularProgressColor,
        }}
      />
      <Typography className={classes.title}>
        {`${value}%`}
      </Typography>
    </Box>
  );
};

export default CircularProgressWithLabel;
