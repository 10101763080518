import { useEffect, useState } from 'react';

export default () => {
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);

  const setHeight = () => {
    setContainerHeight(window.innerHeight);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  return containerHeight;
};
