/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import React from 'react';
import { Button as MuiButton } from '@mui/material';
import classNames from 'classnames';
// Hooks
import useColorTheme from '../../hooks/use-color-theme';
// Styles
import useStyles from './Button.styles';
import Flare from './Animation/Flare';

type ButtonProps = {
  buttonText: string | any;
  className?: string;
  onClick: () => void;
};

export const Button: React.FC<ButtonProps> = ({
  buttonText,
  className,
  onClick,
}) => {
  const classes = useStyles();
  const buttonBgColor = useColorTheme('main');

  return (
    <MuiButton
      className={classNames(classes.button, className)}
      variant={'contained'}
      style={{ backgroundColor: buttonBgColor }}
      onClick={onClick}
    >
      {buttonText}
      <Flare />
    </MuiButton>
  );
};
