import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    marginTop: '7%',
  },
  content: {
    maxWidth: '292px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  cardStyle: {
    border: 'none',
    boxShadow: 'none',
  },
  img: {
    height: 'auto',
    width: '100vw',
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: 10,
    fontSize: 16,
    margin: '5% 0',
  },
  button: {
    display: 'flex',
    marginBottom: '0 !important',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: 315,
  },
  footerText: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    margin: '10px 0',
  },
}));
