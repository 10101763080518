import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from './Swiper/Swiper';
import { Container, Grid, Typography } from '@mui/material';

import { useAppSelector } from '../../store/hooks';
import { selectOnBoardingConfig } from '../../store/config/selectors';

import useStyles from './SwiperPage.styles';

const SwiperPage: React.FC = () => {
  const classes = useStyles();
  const onBoardingConfig = useAppSelector(selectOnBoardingConfig);

  const renderSlides = () =>
    onBoardingConfig.map((slide, i) => (
      <Grid container key={`slide-${slide.title}${i}`}>
        <SwiperSlide>
          <Grid item>
            <img src={slide.image} alt="image" className={classes.image} />
          </Grid>
          <Grid item className={classes.text}>
            <Typography variant="h1" className={classes.title}>
              {slide.title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {slide.subtitle}
            </Typography>
          </Grid>
        </SwiperSlide>
      </Grid>
    ));

  return (
    <Container className={classes.wrap}>
      <Swiper data={renderSlides()} />
    </Container>
  );
};

export default SwiperPage;
