import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import useHeight from '../../hooks/use-height';
import useNavigation from '../../hooks/use-navigation';
import { OPEN, SUCCESS, WELCOME } from '../../constants/events';
import { selectConfig, selectWelcomeScreenConfig } from '../../store/config/selectors';
import Analytics from '../../services/analytics';

import { Button } from '../../components/Button/Button';

import useStyles from './Welcome.styles';

const WelcomePage: React.FC = () => {
  const classes = useStyles();
  const containerHeight = useHeight();
  const welcomeConfig = useSelector(selectWelcomeScreenConfig);
  const config = useSelector(selectConfig);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Analytics.trackEvent(WELCOME, OPEN);
  }, []);

  const handleButtonClick = () => {
    useNavigation(config.flow, location.pathname, navigate);
    Analytics.trackEvent(WELCOME, SUCCESS);
  };

  return (
    <div className={classes.wrap} style={{ height: containerHeight }}>
      <Card className={classes.cardStyle}>
          <img src={welcomeConfig.image} className={classes.img} alt="welcome"/>
      </Card>
      <div className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {welcomeConfig.title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {welcomeConfig.subTitle}
        </Typography>
      </div>
      <div className={classes.footer}>
        <Button
          buttonText={welcomeConfig.btnTitle}
          className={classes.button}
          onClick={handleButtonClick}
        />
        {welcomeConfig.textUnderBtn && (
          <Typography variant="body2" className={classes.footerText}>
            {welcomeConfig.textUnderBtn}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
