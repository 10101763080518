import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: '100vh',
    width: '100vw',
  },
  title: {
    fontSize: 25,
    fontWeight: '700',
    width: '80%',
    margin: '5vh auto'
  },
}));
