import React from 'react';
import useStyles from './Flare.styles';

const Flare = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrap}>
      <div className={classes.flare} />
    </div>
  );
};

export default Flare;