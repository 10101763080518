import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  steps: {
    marginTop: '5vh',
  },
  step: {
    marginBottom: '1vh',
    paddingTop: 60,
    opacity: 0,
    transition: '1.5s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeStep: {
    paddingTop: 0,
    opacity: 1,
  },
  stepTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  stepIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    opacity: 0,
    transition: '1.5s 0.5s ease',
  },
  activeStepIcon: {
    opacity: 1,
  },
}));
