/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/require-await */

import queryString from 'query-string';
import Web2App from '@wowmaking/web2app';
import EvTruck from '@magnus/react-native-evtruck';

import {
  APPSFLYER_LINK,
} from '../constants/general';

export default {
  init() {
    const params = queryString.parse(window.location.search);

    const attribution = {
      utm_source: params.utm_source || '',
      campaign_name: params.campaign_name || '',
      campaign_id: params.campaign_id || '',
      adset_name: params.adset_name || '',
      adset_id: params.adset_id || '',
      ad_name: params.ad_name || '',
      ad_id: params.ad_id || '',
      http_referer: window.document.referrer || '',
    };
    // @ts-ignore
    EvTruck.trackWebAttribution(attribution);
    Web2App.init({
      onChange: (system: never, p: never) => {
        // оборачиваем в массив, в будущем сможем поддерживать несколько пикселей на сайте и тп
        // @ts-ignore
        EvTruck.trackWebMeasurements({ [system]: [p] });
      },
    });
  },

  async getLinkToApp() {
    return Web2App.addAttributionToLink(APPSFLYER_LINK);
  },
};
