// Libs
import { useSelector } from 'react-redux';
import { Palette, PaletteColorOptions } from '@mui/material/styles';
// Store
import { selectConfig } from '../store/config/selectors';
// Theme
import theme from '../theme';

export default (color: string) => {
  const config = useSelector(selectConfig);
  const colorTheme = config.colorsTheme;
  const paletteColor = theme.palette[colorTheme as keyof Palette];

  return paletteColor[color as keyof PaletteColorOptions];
};
