// Types
import { RootState } from '../index';
import {
  FeatureConfig,
  OnBoardingConfig,
  PreloaderConfig,
  WelcomeConfig,
} from '../../types/config';

export const selectConfig = (state: RootState) => state.config.data;
export const selectIsConfigLoading = (state: RootState): boolean =>
  state.config.isLoading;

export const selectWelcomeScreenConfig = (state: RootState): WelcomeConfig =>
  state.config.data?.welcomeConfig;
export const selectOnBoardingConfig = (state: RootState): OnBoardingConfig =>
  state.config.data?.onboardingConfig;
export const selectPreloaderConfig = (state: RootState): PreloaderConfig =>
  state.config.data?.preloaderConfig;
export const selectFeaturesConfig = (state: RootState): FeatureConfig =>
  state.config.data?.featuresConfig;
