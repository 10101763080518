import { isString, isPlainObject, isArray, isBoolean, isNumber } from 'lodash';
import { MagnusConfig } from '../types/config';

function parseJSONParams(paramsAsString: string, defaultValue: object) {
  try {
    return JSON.parse(paramsAsString) as object;
  } catch (err) {
    return defaultValue;
  }
}

function convertType(value: unknown, defaultValue: unknown) {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  if (isString(defaultValue)) {
    return value || defaultValue;
  }
  if (isPlainObject(defaultValue) || isArray(defaultValue)) {
    return parseJSONParams(value as string, defaultValue as object);
  }
  if (isBoolean(defaultValue)) {
    return !!value;
  }
  if (isNumber(defaultValue)) {
    return value;
  }
  return value;
}

function createRemoteConfigSnapshotNormalizer(
  defaultValues: Record<string, unknown>,
  enableLogger = false
) {
  return function (snapshot: MagnusConfig): {
    remoteConfig: MagnusConfig | Record<string, never>;
    snapshot: MagnusConfig | Record<string, unknown>;
  } {
    if (enableLogger) {
      console.log('Normalizer: param names', Object.keys(defaultValues));
      console.log('Normalizer: snapshot', snapshot);
    }

    const remoteConfig = Object.keys(defaultValues).reduce(
      (convertedData, paramName) =>
        Object.assign(convertedData, {
          [paramName]: convertType(
            (snapshot as Record<string, unknown>)[paramName],
            defaultValues[paramName]
          ),
        }),
      {}
    );

    if (enableLogger) {
      console.log('Normalizer: normalized remoteConfig', remoteConfig);
    }
    return { remoteConfig, snapshot };
  };
}

export default createRemoteConfigSnapshotNormalizer;
