// Constants
import { names } from './names';
// Pages
import WelcomePage from '../pages/Welcome/Welcome';
import SwiperPage from '../pages/Swiper/SwiperPage';
import PreloaderPage from '../pages/Preloader/Preloader';

const pages = {
  [names.WELCOME]: WelcomePage,
  [names.ONBOARDING]: SwiperPage,
  [names.PRELOADER]: PreloaderPage,
}

export default pages;
