import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MagnusConfig } from '../../types/config';
import { defaultMagnusConfig } from './constants';

type InitialState = {
  isLoading: boolean;
  data: MagnusConfig;
  error: string;
};

const initialState: InitialState = {
  isLoading: false,
  data: defaultMagnusConfig,
  error: '',
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    getConfig: (state) => {
      state.isLoading = true;
    },
    getConfigSuccess: (state, { payload }: PayloadAction<MagnusConfig>) => {
      state.data = payload;
      state.isLoading = false;
    },
    getConfigError: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { getConfig, getConfigSuccess, getConfigError } =
  configSlice.actions;

export default configSlice.reducer;
