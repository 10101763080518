/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// Libs
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// Store
import { useAppDispatch } from './store/hooks';
import { getConfig } from './store/config';
import {
  selectIsConfigLoading,
  selectConfig,
  selectWelcomeScreenConfig,
  selectOnBoardingConfig
} from './store/config/selectors';
// Navigation
import pages from './navigation/pages';
import routes from './navigation/routes';
// Utils
import { cacheImagesCascade } from './utils/images';

function App() {
  const dispatch = useAppDispatch();
  const isConfigLoading = useSelector(selectIsConfigLoading);
  const config = useSelector(selectConfig);
  const welcomeConfig = useSelector(selectWelcomeScreenConfig);
  const onBoardingConfig = useSelector(selectOnBoardingConfig);
  const sliderImages = onBoardingConfig.map((slide) => slide.image);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cacheImages = [welcomeConfig.image, ...sliderImages];
  const flow = config.flow;
  const startPath = routes[flow[0]];

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  useEffect(() => {
    const getCacheImagesCascade = async () => {
      await cacheImagesCascade(cacheImages);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getCacheImagesCascade();
  }, [cacheImages]);

  return isConfigLoading ? (
    <CircularProgress />
  ) : (
    <Routes>
      <Route path="*" element={<Navigate to={startPath} replace />} />
      {
        flow.map((name: string) => {
          const route = routes[name];
          const Page = pages[name];

          return (
            <Route
              key={name}
              path={route}
              element={<Page />}
            />
          )
        })
      }
    </Routes>
  );
}

export default App;
