import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

async function cacheImages(srcArray: string[]) {
  return Promise.all(
    srcArray.map(
      (sources: string) =>
        new Promise((res, rej) => {
          const img = new Image();

          img.src = sources;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          img.onload = res();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          img.onerror = rej();
        }),
    ),
  );
}

export async function cacheImagesCascade(srcArray: string[]) {
  return Promise.all(
    _map(
      _chunk(srcArray, 2),
      (g, index) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            cacheImages(g).then(resolve, reject);
          }, 400 * index);
        }),
    ),
  );
}
