import Attribution from './attribution';
import Analytics from './analytics';

const downloadApp = async () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const link = await Attribution.getLinkToApp();
  Analytics.trackEvent('install_app', 'click');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.location.href = link;
};

export default downloadApp;