import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#fff',
    },
    text: {
      primary: '#2E343E',
      secondary: '#848C97',
    },
    primary: {
      main: '#1DBF0F',
      light: '#E8FAE5',
      dark: '#2A59D1',
    },
    secondary: {
      main: '#F57131',
      light: '#FEF1EA',
      dark: '#1F9A83',
    },
    success: {
      main: '#7F8AF0',
      light: '#F2F3FE',
      dark: '#FFECC6',
    },
    info: {
      main: '#1BB07B',
      light: '#E8F7F2',
      dark: '#5E89D4',
    }
  },
});

export default theme;
