import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
  button: {
    fontSize: 20,
    height: 65,
    width: '100%',
    borderRadius: 40,
    color: theme.palette.background.default,
    marginBottom: 50,
    maxWidth: 345,
  },
}));
