// libs
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Store
import { useAppSelector } from '../../../../store/hooks';
import { selectPreloaderConfig } from '../../../../store/config/selectors';
// Hooks
import useColorTheme from '../../../../hooks/use-color-theme';
// Icons
import CheckIcon from '@mui/icons-material/Check';
// Styles
import useStyles from './Steps.styles';

type StepsPropsType = {
  percentage: number;
};

const Steps: React.FC<StepsPropsType> = ({ percentage }) => {
  const preloaderConfig = useAppSelector(selectPreloaderConfig);
  const classes = useStyles();
  const steps = [
    { progress: 1, title: preloaderConfig.steps[0] },
    { progress: 30, title: preloaderConfig.steps[1] },
    { progress: 60, title: preloaderConfig.steps[2] },
    { progress: 90, title: preloaderConfig.steps[3] },
  ];

  return (
    <Box className={classes.steps}>
      {steps.map((step) => {
        const isActiveStep = percentage >= step.progress;
        const stepClassName = `${classes.step} ${isActiveStep ? classes.activeStep : ''}`;
        const stepIconClassName = `${classes.stepIcon} ${isActiveStep ? classes.activeStepIcon : ''}`;
        const iconColor = useColorTheme('main');

        return (
          <Box className={stepClassName} key={step.title}>
            <CheckIcon
              sx={{ color: iconColor }}
              className={stepIconClassName}
            />
            <Typography key={step.title} className={classes.stepTitle}>
              {step.title}
            </Typography>
          </Box>
        )
      })}
    </Box>
  );
};

export default Steps;
