/* eslint-disable @typescript-eslint/unbound-method */

import Mutator from '@magnus/react-native-mutator';
import { all, takeEvery, call, put } from 'redux-saga/effects';

// Types
import { MagnusConfig } from '../../types/config';
// Actions
import { getConfig, getConfigError, getConfigSuccess } from './index';
// Services
import createRemoteConfigSnapshotNormalizer from '../../services/magnusConfigNormalizer';
// Constants
import { defaultMagnusConfig } from './constants';
import Analytics from '../../services/analytics';
import Attribution from '../../services/attribution';

function* getConfigSaga() {
  try {
    yield Attribution.init();
    yield Analytics.init(process.env.REACT_APP_MAGNUS_TOKEN as string);
    yield call(Mutator.init, {
      token: process.env.REACT_APP_MAGNUS_TOKEN as string,
    });
    yield call(
      Mutator.fetch,
      process.env.REACT_APP_MAGNUS_DURATION as unknown as number
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const config: MagnusConfig = yield call(Mutator.activate);
    yield put(
      getConfigSuccess(
        createRemoteConfigSnapshotNormalizer(defaultMagnusConfig)(config)
          .remoteConfig as MagnusConfig
      )
    );
  } catch (e) {
    yield put(getConfigError(e as string));
  }
}

export default function* configSaga() {
  yield all([takeEvery(getConfig, getConfigSaga)]);
}
