import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'inline-flex',
  },
  title: {
    fontSize: 45,
    fontWeight: 700,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultCircularProgress: {
    maxWidth: 260,
    position: 'absolute',
    left: 0,
  },
  activeCircularProgress: {
    maxWidth: 260,
  },
}));
