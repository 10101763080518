/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SwiperCore, { Autoplay, FreeMode, Pagination } from 'swiper';
import { Container, Grid } from '@mui/material';
import { Swiper as Swipe } from 'swiper/react';
// Components
import { Button } from '../../../components/Button/Button';
// Hooks
import useHeight from '../../../hooks/use-height';
import useColorTheme from '../../../hooks/use-color-theme';
import useNavigation from '../../../hooks/use-navigation';
// Store
import { selectConfig } from '../../../store/config/selectors';
// Styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { makeStyles } from '@mui/styles';

import Analytics from '../../../services/analytics';
import { OPEN, SUCCESS, SWIPER } from '../../../constants/events';
import { RootState } from '../../../store';

import useStyles from './Swiper.styles';

SwiperCore.use([Pagination, Autoplay]);

type SwiperProps = {
  data: any;
};

export const Swiper: React.FC<SwiperProps> = ({ data }) => {
  const swipeButtonText = useSelector<RootState>(
    (state) => state.config.data?.swipeButtonText
  );
  const lightPaginationColor = useColorTheme('light');
  const primaryPaginationColor = useColorTheme('main');

  useEffect(() => {
    Analytics.trackEvent(SWIPER, OPEN);
  }, []);

  const stylesPagination = makeStyles(() => ({
    pagination: {
      backgroundColor: lightPaginationColor,
      width: 10,
      height: 10,
      display: 'inline-block',
      borderRadius: 8,
      margin: '-10px 3px',
    },
    active: { backgroundColor: primaryPaginationColor },
  }));
  const styles = stylesPagination();

  const containerHeight = useHeight();
  const classes = useStyles();
  const bulletClass = styles.pagination;
  const bulletActiveClass = styles.active;
  const clickableClass = classes.swiperContainerHorizontal;
  const ref: React.MutableRefObject<any> = useRef();
  const config = useSelector(selectConfig);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Analytics.trackEvent(SWIPER, OPEN);
  }, []);

  const handleBtnClick = () => {
    let swiper: any;
    let isLastSlide: any;

    if (ref) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      swiper = ref.current.firstChild.swiper;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      isLastSlide = swiper.isEnd;
    }

    if (isLastSlide) {
      useNavigation(config.flow, location.pathname, navigate);
      Analytics.trackEvent(SWIPER, SUCCESS);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      swiper.slideNext();
    }
  };

  return (
    <Container
      ref={ref}
      className={classes.wrap}
      style={{ height: containerHeight }}
    >
      <Swipe
        className={classes.swipe}
        allowSlideNext
        autoplay={false}
        loop={false}
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides
        modules={[FreeMode, Pagination]}
        pagination={{
          clickable: true,
          bulletClass,
          bulletActiveClass,
          clickableClass,
        }}
      >
        <Grid>{data}</Grid>
      </Swipe>
      <Grid className={classes.buttonStyle}>
        <Button buttonText={swipeButtonText} onClick={handleBtnClick} />
      </Grid>
    </Container>
  );
};
