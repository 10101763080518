import routes from '../navigation/routes';
import downloadApp from '../services/action';

export default (flow: string[], pathname:string, navigate: (route: string) => void ) => {
  const currentPageName = Object.keys(routes).find(key => routes[key] === pathname);
  const currentPageIndex = flow.findIndex((item) => item === currentPageName);
  const isLastPageIndex = flow.length - 1 === currentPageIndex;

  if (isLastPageIndex) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    downloadApp();
  } else {
    const nextPageName = flow[currentPageIndex + 1];
    const nextPageRoute = routes[nextPageName];

    navigate(nextPageRoute);
  }
};
