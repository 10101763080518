import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  wrap: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
    padding: '25px 0 40px 0',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: 10,
    fontSize: 16,
  },
  text: {
    marginTop: '10%',
  },
  image: {
    width: 283,
    height: 327,
    '@media screen and (min-height: 400px)': {
      width: 252,
      height: 270,
    },
    '@media screen and (min-height: 610px)': {
      width: 315,
      height: 364,
    }
  },
}));
