import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  wrap: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  swipe: {
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    maxWidth: 375,
    height: 'auto',
    textAlign: 'center',
  },
  swiperContainerHorizontal: {
    marginBottom: '25%',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: 10,
    fontSize: 16,
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
