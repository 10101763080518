import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
  wrap: {
    position: 'absolute',
    display: 'block',
    width: 320,
    animation: `$slideDown 1s infinite`,
  },
  flare: {
    background: theme.palette.background.default,
    width: 30,
    height: 150,
    top: -73,
    right: 0,
    opacity: 0.1,
    position: 'absolute',
    transform: 'rotate(35deg)',
  },
  '@keyframes slideDown': {
    from: {
      transform: 'translate3d(-100%, 0, 0)',
      visibility: 'visible',
    },
    to: { transform: 'translate3d(0, 0, 0)' },
  },
}));
