// libs
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// Constants
import { OPEN, PRELOADER } from '../../constants/events';
// Store
import Analytics from '../../services/analytics';
import { useAppSelector } from '../../store/hooks';
import {
  selectConfig,
  selectPreloaderConfig,
} from '../../store/config/selectors';
// Components
import CircularProgressWithLabel from './components/CircularProgressWithLabel/CircularProgressWithLabel';
import Steps from './components/Steps/Steps';
import { Button } from '../../components/Button/Button';
// Hooks
import useNavigation from '../../hooks/use-navigation';
// Styles
import useStyles from './Preloader.styles';
const PreloaderPage = () => {
  const [percentage, setStage] = useState(0);
  const preloaderConfig = useAppSelector(selectPreloaderConfig);
  const classes = useStyles();
  const config = useSelector(selectConfig);
  const navigate = useNavigate();
  const location = useLocation();
  const isRedirect = percentage >= 100

  useEffect(() => {
    Analytics.trackEvent(PRELOADER, OPEN);
  }, []);

  let timer: NodeJS.Timeout | undefined;

  const updatePercentage = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setTimeout(() => setStage(percentage + 1), 100);
    return () => clearTimeout(timer as NodeJS.Timeout);
  }, [percentage]);

  const handleClick = useCallback(() => {
    useNavigation(config.flow, location.pathname, navigate);
  }, [config.flow, location.pathname, navigate]);

  useEffect(() => {
    if (!isRedirect) {
      updatePercentage();
    } else {
      handleClick();
    }
  }, [isRedirect, updatePercentage, handleClick]);

  return (
    <Container className={classes.container}>
      <Typography className={classes.title}>
        {preloaderConfig.processTitle}
      </Typography>
      <CircularProgressWithLabel value={percentage} />
      <Steps percentage={percentage} />
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        isRedirect && <Button buttonText={preloaderConfig.button} onClick={handleClick} />
      }
    </Container>
  );
};

export default PreloaderPage;
