import { names } from '../../navigation/names';

export const defaultMagnusConfig = {
  flow: [names.WELCOME, names.ONBOARDING, names.PRELOADER],
  colorsTheme: 'primary',
  swipeButtonText: 'Continue',
  motivationConfig: {
    title: '',
    text: '',
    buttonTitle: '',
    image: '',
  },
  welcomeConfig: {
    title: 'Make estimates and invoices with ease!',
    subTitle: 'For all types of small businesses and freelancers',
    btnTitle: 'Continue',
    textUnderBtn: 'Join the millions now',
    image: 'https://abience.b-cdn.net/images/welcome@2x.webp',
  },
  featuresConfig: {
    title: 'Make your life better',
    subTitle: 'AI recommendations based on your needs an uniqueness',
    buttonTitle: 'Continue',
    image: 'https://abience.b-cdn.net/images/relax@2x.webp',
  },
  onboardingConfig: [
    {
      image: 'PRODUCTIVITY_1',
      title: 'Great-looking, professional invoices',
      subtitle: 'Create and send it in under 1 minute',
    },
    {
      image: 'PRODUCTIVITY_3',
      title: ' Make invoices without any training',
      subtitle: 'Review your edits before you send it',
    },
    {
      image: 'PRODUCTIVITY_2',
      title: 'Match your brand',
      subtitle: 'Add your logo and sync the color',
    },
  ],
  preloaderConfig: {
    processTitle: "We're creating a plan for you",
    doneTitle: 'Your plan is made',
    steps: [
      'Preparing a habit-building course',
      'Configuring your Habit Coach',
      'Analyzing research base',
      'Personalizing insights',
    ],
    button: 'Continue'
  },
  surveyConfig: [
    {
      question: '1. What are the things you want to change',
      options: [
        'Fall asleep faster',
        'Improve focus',
        'Reduce stress',
        'Increase happiness',
        'Listen to soothing music',
        'Create a healthy habit',
        'Boost energy',
        'Calm anxiety',
      ],
      isResponseMultiple: true,
    },
    {
      question: '2. What are the things you want to change',
      options: [
        'Fall asleep faster',
        'Improve focus',
        'Reduce stress',
        'Increase happiness',
        'Listen to soothing music',
        'Create a healthy habit',
        'Boost energy',
      ],
      isResponseMultiple: true,
    },
    {
      question: '3. What are the things you want to change',
      options: ['Fall asleep faster', 'Improve focus', 'Reduce stress'],
      isResponseMultiple: true,
    },
    {
      question: '4. What are the things you want to change',
      options: ['Fall asleep faster', 'Improve focus'],
      isResponseMultiple: false,
    },
    {
      question: '5. What are the things you want to change',
      options: ['Fall asleep faster', 'Improve focus'],
      isResponseMultiple: false,
    },
  ],
};
